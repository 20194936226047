var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo mb-5"}),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1 mt-5",attrs:{"title-tag":"h3"}},[_vm._v(" CID Book Appointment ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Select Appoint For Person","label-for":"user_id"}},[_c('validation-provider',{attrs:{"name":"Appoint For Person","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"user_id","placeholder":"Select Appoint For Person","options":_vm.users,"label":"designation","reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var designation = ref.designation;
return [_c('span',[_vm._v(" "+_vm._s(designation)+" ("+_vm._s(name)+")")])]}}],null,true),model:{value:(_vm.form.user_id),callback:function ($$v) {_vm.$set(_vm.form, "user_id", $$v)},expression:"form.user_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Select Person Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"Person Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"type","placeholder":"Select Person Type","options":_vm.types,"label":"name","reduce":function (option) { return option.value; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.form.type == 2)?_c('b-form-group',{attrs:{"label":"BPN / CIV Number","label-for":"bpn_civ_number"}},[_c('validation-provider',{attrs:{"rules":"required","name":"How Many Person"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bpn_civ_number","state":errors.length > 0 ? false : null,"name":"bpn_civ_number","placeholder":"Enter Your BPN / CIV Number"},model:{value:(_vm.form.bpn_civ_number),callback:function ($$v) {_vm.$set(_vm.form, "bpn_civ_number", $$v)},expression:"form.bpn_civ_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2365453943)})],1):_vm._e(),(_vm.form.type == 1)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Full Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name","placeholder":"Enter Your Full Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2873595979)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Designation","label-for":"designation"}},[_c('validation-provider',{attrs:{"name":"Designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"designation","state":errors.length > 0 ? false : null,"name":"designation","placeholder":"Enter Your Designation"},model:{value:(_vm.form.designation),callback:function ($$v) {_vm.$set(_vm.form, "designation", $$v)},expression:"form.designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4256190616)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|min:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","maxlength":11,"state":errors.length > 0 ? false : null,"name":"phone","placeholder":"Enter Your Phone Number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2866989989)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":"Enter Your Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2428751640)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Age","label-for":"age"}},[_c('validation-provider',{attrs:{"name":"Age"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"age","state":errors.length > 0 ? false : null,"name":"age","placeholder":"Enter Your Age"},model:{value:(_vm.form.age),callback:function ($$v) {_vm.$set(_vm.form, "age", $$v)},expression:"form.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4128700568)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"NID","label-for":"nid"}},[_c('validation-provider',{attrs:{"name":"NID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nid","state":errors.length > 0 ? false : null,"name":"nid","placeholder":"Enter Your NID"},model:{value:(_vm.form.nid),callback:function ($$v) {_vm.$set(_vm.form, "nid", $$v)},expression:"form.nid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1754061528)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Select Gender","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"type","placeholder":"Select Gender","options":_vm.genders,"label":"name","reduce":function (option) { return option.value; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3226816651)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Present Address","label-for":"present_address"}},[_c('validation-provider',{attrs:{"name":"Present Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"present_address","state":errors.length > 0 ? false : null,"name":"present_address","placeholder":"Enter Your Present Address","rows":"2"},model:{value:(_vm.form.present_address),callback:function ($$v) {_vm.$set(_vm.form, "present_address", $$v)},expression:"form.present_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3701938630)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Permanent Address","label-for":"permanent_address"}},[_c('validation-provider',{attrs:{"name":"Permanent Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"permanent_address","state":errors.length > 0 ? false : null,"name":"permanent_address","placeholder":"Enter Your Permanent Address","rows":"2"},model:{value:(_vm.form.permanent_address),callback:function ($$v) {_vm.$set(_vm.form, "permanent_address", $$v)},expression:"form.permanent_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2931544806)})],1)],1)],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Appointment Purposes","label-for":"appointment_purposes"}},[_c('validation-provider',{attrs:{"name":"Appointment Purposes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"appointment_purposes","state":errors.length > 0 ? false : null,"name":"appointment_purposes","placeholder":"Enter Your Appointment Purposes","rows":"2"},model:{value:(_vm.form.appointment_purposes),callback:function ($$v) {_vm.$set(_vm.form, "appointment_purposes", $$v)},expression:"form.appointment_purposes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Desire Date and Time","label-for":"desire_date_time"}},[_c('validation-provider',{attrs:{"name":"Desire Date and Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Select Desire Date and Time","config":{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }},model:{value:(_vm.form.desire_date_time),callback:function ($$v) {_vm.$set(_vm.form, "desire_date_time", $$v)},expression:"form.desire_date_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"How Many Person ?","label-for":"how_many_person"}},[_c('validation-provider',{attrs:{"rules":"required","name":"How Many Person"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"how_many_person","state":errors.length > 0 ? false : null,"name":"how_many_person","placeholder":"Enter Your How Many Person ?"},model:{value:(_vm.form.how_many_person),callback:function ($$v) {_vm.$set(_vm.form, "how_many_person", $$v)},expression:"form.how_many_person"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-5",attrs:{"disabled":_vm.send,"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }