<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable quotes -->
<!-- eslint-disable quotes -->
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo mb-5">
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">CID Appointment Book</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h3" class="font-weight-bold mb-1 mt-5">
            CID Book Appointment
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group
                label="Select Appoint For Person"
                label-for="user_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Appoint For Person"
                  rules="required"
                >
                  <v-select
                    id="user_id"
                    v-model="form.user_id"
                    placeholder="Select Appoint For Person"
                    :options="users"
                    label="designation"
                    :reduce="(option) => option.id"
                  >
                    <template #option="{ name, designation }">
                      <span> {{ designation }} ({{ name }})</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Select Person Type" label-for="type">
                <validation-provider
                  #default="{ errors }"
                  name="Person Type"
                  rules="required"
                >
                  <v-select
                    id="type"
                    v-model="form.type"
                    placeholder="Select Person Type"
                    :options="types"
                    label="name"
                    :reduce="(option) => option.value"
                  >
                    <template #option="{ name }">
                      <span> {{ name }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="BPN / CIV Number"
                label-for="bpn_civ_number"
                v-if="form.type == 2"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="How Many Person"
                >
                  <b-form-input
                    id="bpn_civ_number"
                    v-model="form.bpn_civ_number"
                    :state="errors.length > 0 ? false : null"
                    name="bpn_civ_number"
                    placeholder="Enter Your BPN / CIV Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div v-if="form.type == 1">
                <b-row>
                  <b-col lg="6">
                    <b-form-group label="Full Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="Full Name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Enter Your Full Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group label="Designation" label-for="designation">
                      <validation-provider
                        #default="{ errors }"
                        name="Designation"
                        rules="required"
                      >
                        <b-form-input
                          id="designation"
                          v-model="form.designation"
                          :state="errors.length > 0 ? false : null"
                          name="designation"
                          placeholder="Enter Your Designation"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group label="Phone Number" label-for="phone">
                      <validation-provider
                        #default="{ errors }"
                        name="Phone Number"
                        rules="required|min:11"
                      >
                        <b-form-input
                          id="phone"
                          :maxlength="11"
                          v-model="form.phone"
                          :state="errors.length > 0 ? false : null"
                          name="phone"
                          placeholder="Enter Your Phone Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group label="Email" label-for="email">
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="form.email"
                          :state="errors.length > 0 ? false : null"
                          name="email"
                          placeholder="Enter Your Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group label="Age" label-for="age">
                      <validation-provider #default="{ errors }" name="Age">
                        <b-form-input
                          id="age"
                          v-model="form.age"
                          :state="errors.length > 0 ? false : null"
                          name="age"
                          placeholder="Enter Your Age"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group label="NID" label-for="nid">
                      <validation-provider #default="{ errors }" name="NID">
                        <b-form-input
                          id="nid"
                          v-model="form.nid"
                          :state="errors.length > 0 ? false : null"
                          name="nid"
                          placeholder="Enter Your NID"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group label="Select Gender" label-for="type">
                      <validation-provider
                        #default="{ errors }"
                        name="Gender"
                        rules="required"
                      >
                        <v-select
                          id="type"
                          v-model="form.gender"
                          placeholder="Select Gender"
                          :options="genders"
                          label="name"
                          :reduce="(option) => option.value"
                        >
                          <template #option="{ name }">
                            <span> {{ name }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group
                      label="Present Address"
                      label-for="present_address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Present Address"
                        rules="required"
                      >
                        <b-form-textarea
                          id="present_address"
                          v-model="form.present_address"
                          :state="errors.length > 0 ? false : null"
                          name="present_address"
                          placeholder="Enter Your Present Address"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group
                      label="Permanent Address"
                      label-for="permanent_address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Permanent Address"
                        rules="required"
                      >
                        <b-form-textarea
                          id="permanent_address"
                          v-model="form.permanent_address"
                          :state="errors.length > 0 ? false : null"
                          name="permanent_address"
                          placeholder="Enter Your Permanent Address"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-form-group
                label="Appointment Purposes"
                label-for="appointment_purposes"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Appointment Purposes"
                  rules="required"
                >
                  <b-form-textarea
                    id="appointment_purposes"
                    v-model="form.appointment_purposes"
                    :state="errors.length > 0 ? false : null"
                    name="appointment_purposes"
                    placeholder="Enter Your Appointment Purposes"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row>
                <b-col lg="6">
                  <b-form-group
                    label="Desire Date and Time"
                    label-for="desire_date_time"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Desire Date and Time"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="form.desire_date_time"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Select Desire Date and Time"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i',
                        }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group
                    label="How Many Person ?"
                    label-for="how_many_person"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="How Many Person"
                    >
                      <b-form-input
                        id="how_many_person"
                        v-model="form.how_many_person"
                        :state="errors.length > 0 ? false : null"
                        name="how_many_person"
                        placeholder="Enter Your How Many Person ?"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- submit buttons -->
              <b-button
                class="mb-5"
                :disabled="send"
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Submit
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import flatPickr from "vue-flatpickr-component";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email, min, max } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        user_id: null,
        type: 1,
        name: "",
        designation: "",
        phone: "",
        email: "",
        nid: "",
        age: "",
        gender: null,
        present_address: "",
        permanent_address: "",
        appointment_purposes: "",
        how_many_person: "",
        desire_date_time: "",
        bpn_civ_number: "",
      },
      send: false,
      types: [
        {
          value: 1,
          name: "General People",
        },
        {
          value: 2,
          name: "Police",
        },
      ],
      genders: [
        {
          value: 1,
          name: "Male",
        },
        {
          value: 2,
          name: "Female",
        },
        {
          value: 3,
          name: "Other",
        },
      ],
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
      min,
      max,
    };
  },
  mounted() {
    this.$store.dispatch("getAppointmentUsers");
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    users() {
      return this.$store.getters.getAppointmentUsers;
    },
  },

  methods: {
    store() {
      this.send = true;
      axios
        .post("/book/appointment/store", this.form)
        .then((res) => {
          this.s(res.data.message);
          this.Close();
          this.send = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((e) => {
          if (e.response.status === 401) {
            if (e.response.data.errors.user_id)
              this.e(e.response.data.errors.user_id[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.designation)
              this.e(e.response.data.errors.designation[0]);
            if (e.response.data.errors.phone)
              this.e(e.response.data.errors.phone[0]);
            if (e.response.data.errors.email)
              this.e(e.response.data.errors.email[0]);
            if (e.response.data.errors.nid)
              this.e(e.response.data.errors.nid[0]);
            if (e.response.data.errors.age)
              this.e(e.response.data.errors.age[0]);
            if (e.response.data.errors.gender)
              this.e(e.response.data.errors.gender[0]);
            if (e.response.data.errors.present_address)
              this.e(e.response.data.errors.present_address[0]);
            if (e.response.data.errors.permanent_address)
              this.e(e.response.data.errors.permanent_address[0]);
            if (e.response.data.errors.appointment_purposes)
              this.e(e.response.data.errors.appointment_purposes[0]);
            if (e.response.data.errors.how_many_person)
              this.e(e.response.data.errors.how_many_person[0]);
            if (e.response.data.errors.desire_date_time)
              this.e(e.response.data.errors.desire_date_time[0]);
            if (e.response.data.errors.bpn_civ_number)
              this.e(e.response.data.errors.bpn_civ_number[0]);

            this.send = false;
          }
        });
    },
    Close() {
      this.form.user_id = null;
      this.form.type = 1;
      this.form.name = "";
      this.form.designation = "";
      this.form.phone = "";
      this.form.email = "";
      this.form.nid = "";
      this.form.age = "";
      this.form.gender = null;
      this.form.present_address = "";
      this.form.permanent_address = "";
      this.form.appointment_purposes = "";
      this.form.how_many_person = "";
      this.form.desire_date_time = "";
      this.form.bpn_civ_number = "";
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.store();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
